let isClickable = false

$('.js-mobStylesPrev').on('click', (e) => {
  stylesChange(e, -1)
})

$('.js-mobStylesNext').on('click', (e) => {
  stylesChange(e, 1)
})

const stylesChange = (e, number) => {
  const target = $(e.currentTarget)
  const parent = target.parent()
  const list = parent.find('.js-mobStylesList')
  const next = parent.find('.js-mobStylesNext')
  const prev = parent.find('.js-mobStylesPrev')

  const stylesCount = $('.entering-mob-home-styles__item', list).length

  next.removeClass('is_disabled')
  prev.removeClass('is_disabled')

  if (isClickable) {
    return
  }

  isClickable = true

  let currentIndex = $('.entering-mob-home-styles__item.is_active', list).index()

  currentIndex += number

  if (currentIndex === 0) {
    prev.addClass('is_disabled')
  }

  if (currentIndex === stylesCount - 1) {
    currentIndex = stylesCount - 1

    next.addClass('is_disabled')
  }

  $('.entering-mob-home-styles__item', list)
    .eq(currentIndex)
    .addClass('is_active')
    .siblings()
    .removeClass('is_active')

  $('.entering-mob-home-styles__item', list).eq(currentIndex).trigger('click')

  $('.js-mobStylesPoints').addClass('entering-mob-points-hide')
  setTimeout(() => {
    $('.js-mobStylesPoints').removeClass('entering-mob-points-hide')
    isClickable = false
  }, 2000)
}
