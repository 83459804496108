import Mustache from 'mustache'
import Swiper from 'swiper'
import { Autoplay, Pagination, Navigation, Zoom } from 'swiper/modules'
import { isSafari, getDynamicSizeValue } from '../utils/utils'
export default class CatalogProduct {

  constructor ($el, basketManager, app) {
    this.DOM = {}
    this.DOM.$el = $el

    this.ANIMATE_TIMEOUT = 3000
    this.$editionTabItems = this.DOM.$el.find('.js-editionTabItems')
    this.editionItems = []

    this.data = this.DOM.$el.find('.js-data-detail').length ? $.parseJSON(this.DOM.$el.find('.js-data-detail').html()) : {}

    this.checkedRarity = this.data.ITEM.RARITY
    this.checkedColor = this.data.ITEM.ID
    window.checkedOffer = this.checkedOffer = 0

    this.basketManager = basketManager
    this.app = app

    this.disabledClick = false

    this.init()
  }

  init () {
    this.DOM.$previewSlider = this.DOM.$el.find('.js-preview-slider')
    this.previewSliderTemplate = this.DOM.$el
      .find('.js-preview-slider-template')
      .html()

    this.render()
    this.bindEvents()

    // смена обводки стиля
    // this.changeSvgBorderColor('#fff')

    if (isSafari) {
      this.updatePointOffsetPath()

      $(window).on('resize', () => {
        this.updatePointOffsetPath()
      })
    }
  }

  circlePath(cx, cy, r) {
    const r2 = r * 2
    return `M${cx},${cy}a${r},${r} 0 1,0 ${r2},0a${r},${r} 0 1,0 -${r2},0Z`
  }

  updatePointOffsetPath() {
    const cx = getDynamicSizeValue(21.125)
    const cy = getDynamicSizeValue(424.914)
    const r = getDynamicSizeValue(474.789)

    const d = this.circlePath(cx, cy, r)

    $('.js-catalogRare .catalog-rare__item[data-id]').css('offset-path', `path("${d}")`)
  }

  render() {
    this.$catalogColorSizes = $('.js-detail-page .js-color-sizes')

    $('.js-catalogRare .catalog-rare__item[data-id]').each((index, el) => {
      this.editionItems.push({
        index,
        d: $(el).attr('d'),
        id: $(el).attr('data-id'),
        title: $(el).attr('data-title'),
        disabled: $(el).attr('data-disabled'),
        offsetDistance: $(el).attr('data-offset-distance'),
      })

      $(el).css('offset-distance', $(el).attr('data-offset-distance'))
    })

    this.catalogColorSizesTemplate = $('.js-detail-page #js-color-sizes-template').html()

    this.renderEditionItems()
    if (this.data.RARITIES_SELECTED_INDEX) {
      this.moveItemsRender(this.data.RARITIES_SELECTED_INDEX)
    } else {
      this.moveItemsRender(0)
    }
    this.renderColorSizes()
    this.renderPreviewSlider()
  }

  bindEvents() {
    let _this = this
    $(document).on('click', '.js-editionTabItem', (e) => {
      const currentIndex = $(e.currentTarget).index()
      this.checkedRarity = $(e.currentTarget).data('id')

      console.log('edition click')

      if (this.disabledClick) {
        return
      }

      _this.moveItemsRender(currentIndex)
      _this.renderColorSizes(true)

      this.disabledClick = true
    })

    $(document).on('click', '.js-detail-page .js-catalogSizeSlider .swiper-slide', function () {
      const currentOfferId = $(this).data('offer-id')
      window.checkedOffer = _this.checkedOffer = currentOfferId

      _this.renderAddToBasketButton()
    })

    $(document).on('click', '.js-detail-page .catalog-color__item', function (e) {
      _this.checkedColor = $(e.currentTarget).data('item-id')
      $(e.currentTarget)
        .addClass('is_active')
        .siblings()
        .removeClass('is_active')

      _this.renderPreviewSlider()
      _this.renderSizesSlider()
      _this.setUrl()
    })

    $(document).on('click', '.js-detail-page .js-add-to-basket', function (e) {
      if (_this.checkedOffer && _this.checkedOffer > 0) {
        _this.sendRequest($(e.currentTarget).data('action'), [
          { name: 'offerId', value: _this.checkedOffer}
        ])
       _this.basketManager.addBasket(_this.checkedOffer)
      }
    })

    $(document).on('click', '.js-waitingListProductAdd', function (e) {
      _this.app.waitingList.offerId = _this.checkedOffer
      $('.auth-aside').data('offerId', _this.checkedOffer)
    })
  }

  renderTabItem(item) {
    this.$editionTabItems.append(
      `<div class="catalog-fabric__item js-editionTabItem" data-disabled="${item.disabled}" data-id="${item.id}">${item.title}</div>`,
    )
  }

  renderEditionItems() {
    this.$editionTabItems.html('')

    this.editionItems.forEach((el) => {
      this.renderTabItem(el)
    })

    // $('.js-editionTabItem').eq(2).addClass('is_active')
  }

  renderPreviewSlider() {
    const $activeColorId = this.data.ITEMS[this.checkedRarity].ITEMS.filter(el => el.ID == this.checkedColor)

    if ($activeColorId[0])
    {
      this.DOM.$previewSlider.html(
          Mustache.render(this.previewSliderTemplate, $activeColorId[0])
      )
    }

    if (this.DOM.$previewSlider.find('.js-productSwiper').length > 0) {
      this.DOM.$previewSlider.find('.js-productSwiper').each((_, swiperEl) => {
        const pagination = $(swiperEl).find('.swiper-pagination')
        const parent = $(swiperEl).parent()

        const slideChange = () => {
          const decor = parent.find('.catalog-product-swiper-decor path')
          const fill = $(swiperEl)
            .find('.swiper-slide-active')
            .attr('data-color')

          if (fill) {
            decor.attr('fill', fill)
          }
        }

        new Swiper($(swiperEl)[0], {
          zoom: true,
          modules: [Autoplay, Pagination, Navigation, Zoom],
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          spaceBetween: 10,
          // autoplay: {
          //   delay: 5000,
          //   disableOnInteraction: false,
          // },
          loop: true,
          pagination: {
            el: pagination[0],
            clickable: true,
          },
          on: {
            init() {
              slideChange()
            },
            slideChangeTransitionEnd() {
              slideChange()
            },
          },
        })
      })
    }

    $('.js-rarity').html(this.checkedRarity)
    if ($activeColorId[0])
    {
      $('.js-detail-text').html($activeColorId[0].DETAIL_TEXT)
    }
  }

  moveItemsRender(currentIndex) {
    this.editionItems = this.moveItems(this.editionItems, currentIndex)

    this.moveCirclePoints(currentIndex)

    this.$editionTabItems.addClass('is_disabled')

    setTimeout(() => {
      this.renderEditionItems()
    }, this.ANIMATE_TIMEOUT - 1500)

    setTimeout(() => {
      this.resetCirclePoints()
      this.$editionTabItems.removeClass('is_disabled')
      this.disabledClick = false
    }, this.ANIMATE_TIMEOUT)
  }

  moveCirclePoints(currentIndex) {
    const shift = {
      0: [94.1, 98.3, 102.5, 185.5, 189.5],
      1: [89.5, 94.1, 98.3, 102.5, 185.5],
      2: [85.5, 89.5, 94.1, 98.3, 102.5],
      3: [202.5, 185.5, 189.5, 194.1, 198.3],
      4: [198.3, 202.5, 185.5, 189.5, 194.1],
    }

    const currentShift = shift[currentIndex]

    $('.js-catalogRare .catalog-rare__item[data-id]').each((index, el) => {
      const transition = currentIndex < 2 ? `${2000 - index * 150}ms` : `${2000 + index * 150}ms`

      $(el)
        .css('transition', transition)
        .css('offset-distance', `${currentShift[index]}%`)
    })

    $('.js-catalogRare .catalog-rare__item[data-id]')
      .eq(currentIndex)
      .addClass('is_active')
      .siblings()
      .removeClass('is_active')
    $('.js-editionTabItem').addClass('is_hidden')
  }

  resetCirclePoints() {
    const $fields = $('.js-catalogRare .catalog-rare__item[data-id]')
    $fields.each((_, el) => {
      const distance = $(el).css('offset-distance').replace('%', '')
      const currentDistance = distance > 105 ? distance - 100 : distance

      $(el)
        .css('transition', 'none')
        .css('offset-distance', `${currentDistance}%`)
    })
    $fields
      .sort((prev, next) => {
        const an = +$(prev).css('offset-distance').replace('%', '')
        const bn = +$(next).css('offset-distance').replace('%', '')

        if (an > bn) {
          return 1
        }
        if (an < bn) {
          return -1
        }
        return 0
      })
      .appendTo($fields.parent())
  }

  moveItems(items, currentIndex) {
    const copied = [...items]

    const shift = {
      0: 2,
      1: 1,
      2: 0,
      3: 4,
      4: 3,
    }

    return copied.concat(copied.splice(0, copied.length - shift[currentIndex]))
  }

  renderColorSizes(change = false) {
    const rendered = Mustache.render(this.catalogColorSizesTemplate, this.data.ITEMS[this.checkedRarity])

    const delay = this.$catalogColorSizes.data('delay')

    setTimeout(() => {
      this.$catalogColorSizes.html(rendered)
      if (change) {
        $('.catalog-mob__color').find('.catalog-mob-accordion__title').text(
          this.$catalogColorSizes.find('.catalog-color__item').eq(0).text()
        )
        this.$catalogColorSizes.find('.catalog-color__item').eq(0).addClass('is_active')
        this.checkedColor = this.$catalogColorSizes.find('.catalog-color__item').eq(0).data('item-id')
      } else {
        $('.catalog-mob__color').find('.catalog-mob-accordion__title').text(
          this.$catalogColorSizes.find('.catalog-color__item[data-item-id=' + this.checkedColor +' ]').text()
        )
        this.$catalogColorSizes.find('.catalog-color__item[data-item-id=' + this.checkedColor +' ]').addClass('is_active')
      }
      this.renderPreviewSlider()
      this.renderSizesSlider()
      if (change) {
        this.setUrl()
      }
    }, delay)
  }

  renderSizesSlider() {
    const $catalogSliderSizes = $('.js-detail-page .js-slider-sizes')
    const $activeColorId = this.data.ITEMS[this.checkedRarity].ITEMS.filter(el => el.ID == this.checkedColor)

    const template = $('.js-detail-page #js-slider-sizes-template').html()

    if ($activeColorId[0]){
      const rendered = Mustache.render(template, $activeColorId[0])
      $catalogSliderSizes.html(rendered)

      window.updateCatalogSizeSliderSlider()

      window.checkedOffer = this.checkedOffer = $catalogSliderSizes.find('.swiper-slide-active').data('offer-id')

      this.renderAddToBasketButton()
    }
  }

  renderAddToBasketButton() {
    const $catalogAddToBasket = $('.js-detail-page .js-add-to-basket-container')
    const $activeColor = this.data.ITEMS[this.checkedRarity].ITEMS.filter(el => el.ID == this.checkedColor)
    const $activeOffer = $activeColor[0].OFFERS.filter(el => el.ID == this.checkedOffer)

    if ($activeOffer.length > 0)
    {
      const template = $('.js-detail-page #js-add-to-basket-template').html()

      const rendered = Mustache.render(template, $activeOffer[0])
      $catalogAddToBasket.html(rendered)
    }
  }

  setUrl() {
    if (window.history.replaceState) {
      const $activeColorId = this.data.ITEMS[this.checkedRarity].ITEMS.filter(el => el.ID == this.checkedColor)
      window.history.replaceState($activeColorId[0], $activeColorId[0].NAME, $activeColorId[0].DETAIL_PAGE_URL)
    }
  }

  sendRequest (url, additionalData = []) {
    let data = []

    data.push({
      name: 'sessid',
      value: window.app.sessid
    })

    if (additionalData.length > 0) {
      data = data.concat(additionalData)
    }

    $.ajax({
      url: url,
      data: data,
      method: 'post'
    }).then(response => {
      if (response && response.data) {
        app.headerBasket.setData(response.data);
        app.basketManager.refresh(response.data, true);
        $('.cart-aside').addClass('is_opened')
        $('body').addClass('body-lock')
      }
    })
  }

  changeSvgBorderColor(color) {
    $('.js-productSvgBorderColor').attr('stop-color', color)
  }
}
