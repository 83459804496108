/* eslint-disable no-plusplus */
import '../vendor/jquery-validation'

if ($('html').attr('lang') === 'ru') {
  $.extend($.validator.messages, {
    required: 'Это поле обязательно для заполнения.',
    remote: 'Пожалуйста, введите правильное значение.',
    email: 'Указан неверный формат e-mail адреса.',
    url: 'Пожалуйста, введите корректный URL.',
    date: 'Пожалуйста, введите корректную дату.',
    dateISO: 'Пожалуйста, введите корректную дату в формате ISO.',
    number: 'Пожалуйста, введите число.',
    digits: 'Пожалуйста, вводите только цифры.',
    creditcard: 'Пожалуйста, введите правильный номер кредитной карты.',
    equalTo: 'Пожалуйста, введите такое же значение ещё раз.',
    extension: 'Пожалуйста, выберите файл с правильным расширением.',
    maxlength: $.validator.format(
      'Максимальная длина описания - {0} символов.',
    ),
    minlength: $.validator.format(
      'Пожалуйста, введите не меньше {0} символов.',
    ),
    rangelength: $.validator.format(
      'Пожалуйста, введите значение длиной от {0} до {1} символов.',
    ),
    range: $.validator.format('Пожалуйста, введите число от {0} до {1}.'),
    max: $.validator.format('Максимальное число - {0}.'),
    min: $.validator.format(
      'Пожалуйста, введите число, большее или равное {0}.',
    ),
  })
}

$('.js-validate').each((_, form) => {
  $(form).validate({
    errorElement: 'span',
    errorClass: 'label-error',
    validClass: 'is-success',
    focusInvalid: false,
    onfocusout(element) {
      this.element(element)
    },
    invalidHandler(_invalid, validator) {
      if (!validator.numberOfInvalids()) return

      validator.errorList[0].element.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      })

      validator.errorList[0].element.focus({ preventScroll: true })
    },
    // errorPlacement() {},
    highlight(element, _errorClass, validClass) {
      if ($(element).attr('type') === 'checkbox') {
        setTimeout(() => {
          const errorLabel = $(element).parent().find('.label-error')
          $(element).parent().append(errorLabel)
        }, 100)
      }

      $(element).parent().addClass('is-error').removeClass(validClass)
    },
    unhighlight(element, _errorClass, validClass) {
      $(element).parent().removeClass('is-error')

      if ($(element).val().length) {
        $(element).parent().addClass(validClass)
      }
    },
  })

  $.validator.addMethod(
    'datebirth',
    (value) => {
      if (value.length === 10) {
        const d = value?.split('.')
        const date = new Date(d[2], Number(d[1]) - 1, d[0])

        if (Number(d[1]) - 1 > 11 || d[0] > 31) {
          return false
        }

        return date < new Date()
      }
      return false
    },
    'Пожалуйста, введите корректную дату.',
  )

  $.validator.addClassRules('js-datebirth', {
    datebirth: true,
  })

  $.validator.addMethod(
    'passwordHard',
    (value, element) => {
      const val = value
      const parent = $(element).parents('.js-password').parent()
      const passwordHard = parent.find('.password-hard')
      let counter = 0

      if (val === '') {
        passwordHard.hide()
      } else {
        passwordHard.show()
      }

      $('.password-hard__description-item', passwordHard).removeClass('is_active')
      $('.password-hard__indicator-item', passwordHard).removeClass('is_active')

      if ($('.js-regnickname').val() !== val) {
        ++counter
        $('.password-hard__indicator-item', passwordHard).eq(0).addClass('is_active')
        $('.password-hard__description-item', passwordHard).eq(0).addClass('is_active')
      }

      if (/[0-9]/.test(val) || /[a-z]/.test(val) || /[A-Z]/.test(val)) {
        ++counter
        $('.password-hard__indicator-item', passwordHard).eq(1).addClass('is_active')
        $('.password-hard__description-item', passwordHard).eq(1).addClass('is_active')
      }

      if (/[a-z]/.test(val) && /[A-Z]/.test(val)) {
        ++counter
        $('.password-hard__indicator-item', passwordHard).eq(2).addClass('is_active')
        $('.password-hard__description-item', passwordHard).eq(2).addClass('is_active')
      }

      if (val.length > 8) {
        ++counter
        $('.password-hard__indicator-item', passwordHard).eq(3).addClass('is_active')
        $('.password-hard__description-item', passwordHard).eq(3).addClass('is_active')
      }

      return counter === 4
    },
    'Пожалуйста, введите корректный пароль.',
  )

  $.validator.addClassRules('js-passwordHard', {
    passwordHard: true,
  })

  $('.js-passwordHard').on('input', (e) => {
    $(e.currentTarget).valid()
  })
})
