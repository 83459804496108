import { getDynamicSizeValue } from '../utils/utils'
/* eslint-disable operator-linebreak */

export class SvgMoveAnimation {
  constructor(options) {
    this.configPoints = options.configPoints
    this.animateTimeout = 2500
    this.resetCircleTimeout = 2500
    this.disabled = false
    this.resetDistance = options.resetDistance
    this.$rootEl = options.rootEl
    this.$svgMoveItem = this.$rootEl.find('.js-svgMoveItem')
    this.$svgCircles = $(`.${this.$rootEl.data('root-circles')}`)
    this.$prevButton = $('.js-catalogRareMobPrev')
    this.$nextButton = $('.js-catalogRareMobNext')

    this.init()
  }

  init() {
    this.initHandlers()
    this.checkNavigationButtons()

    this.updatePointOffsetPath()

    $(window).on('resize', () => {
      this.updatePointOffsetPath()
    })
  }

  circlePath(cx, cy, r) {
    const r2 = r * 2
    return `M0,${cy}a${r},${r} 0 1,0 ${r2},0a${r},${r} 0 1,0 -${r2},0Z`
  }

  updatePointOffsetPath() {
    const cx = getDynamicSizeValue(279.486, 360)
    const cy = getDynamicSizeValue(279.486, 360)
    const r = getDynamicSizeValue(279.486, 360)

    const d = this.circlePath(cx, cy, r)

    $('.catalog-rare-mob__item[data-id]').css('offset-path', `path("${d}")`)
  }

  initHandlers() {
    this.$svgMoveItem.on('click', (e) => this.pointClick(e))

    this.$prevButton.on('click', () => {
      if (this.disabled) {
        return
      }

      this.$rootEl.find('.js-svgMoveItem').eq(3).trigger('click')

      this.disabled = true

      setTimeout(() => {
        this.disabled = false
      }, this.resetCircleTimeout)
    })

    this.$nextButton.on('click', () => {
      if (this.disabled) {
        return
      }

      this.$rootEl.find('.js-svgMoveItem').eq(1).trigger('click')

      this.disabled = true

      setTimeout(() => {
        this.disabled = false
      }, this.resetCircleTimeout)
    })
  }

  pointClick(e) {
    const currentIndex = $(e.currentTarget).index()
    const currentShift = this.configPoints[currentIndex]
    const raresCount = this.$rootEl.find(
      '.js-svgMoveItem:not(.is_hidden)',
    ).length

    const fullRare = raresCount === 5

    if (currentShift) {
      // Если есть 5 редкостей
      if (fullRare) {
        $('[data-id]', this.$svgCircles).each((index, el) => {
          if (!currentShift[index]) {
            return
          }

          const transition =
            currentIndex < 2
              ? `${2000 - index * 150}ms`
              : `${2000 + index * 150}ms`

          $(el).css({
            transition,
            'offset-distance': `${currentShift[index]}%`,
          })
        })
      } else {
        $('[data-id]', this.$svgCircles).each((index, el) => {
          if (!currentShift[index]) {
            return
          }

          $(el).css({
            transition: '1000ms',
            'offset-distance': '100%',
          })
        })

        let nextIndex = currentIndex

        if (currentIndex === 0) {
          nextIndex = 2
        }

        if (currentIndex === 1) {
          nextIndex = currentIndex + 1
        }

        if (currentIndex === 3) {
          nextIndex = currentIndex - 1
        }

        setTimeout(() => {
          $('[data-id]', this.$svgCircles)
            .eq(currentIndex)
            [currentIndex === 3 ? 'insertBefore' : 'insertAfter'](
              $('[data-id]', this.$svgCircles).eq(nextIndex),
            )

          this.$rootEl
            .find('.js-svgMoveItem')
            .eq(currentIndex)
            [currentIndex === 3 ? 'insertBefore' : 'insertAfter'](
              this.$rootEl.find('.js-svgMoveItem').eq(nextIndex),
            )

          this.resetPosition()
        }, 500)

        setTimeout(() => {
          $('[data-id]', this.$svgCircles).each((index, el) => {
            $(el).css({
              transition: '1000ms',
              'offset-distance': `${this.configPoints[nextIndex][index]}%`,
            })
          })
        }, 1000)
      }

      // Desktop points
      $('.catalog-point').each((index, el) => {
        if (!currentShift[index]) {
          return
        }

        const transition =
          currentIndex < 2
            ? `${2000 - index * 150}ms`
            : `${2000 + index * 150}ms`

        $(el).css({
          transition,
          'offset-distance': `${currentShift[index]?.distance}%`,
          r: currentShift[index]?.r,
        })
      })
    }

    $('[data-id]', this.$svgCircles)
      .eq(currentIndex)
      .addClass('is_active')
      .siblings()
      .removeClass('is_active')

    this.$svgMoveItem.addClass('is_disabled')

    setTimeout(() => {
      if (fullRare) {
        this.moveItems(currentIndex)
      }

      this.checkNavigationButtons()

      this.$svgMoveItem.removeClass('is_disabled')
    }, this.animateTimeout - 1000)

    setTimeout(() => {
      if (fullRare) {
        this.resetCirclePoints()
        this.$svgMoveItem.on('click', (ev) => this.pointClick(ev))
      }
    }, this.resetCircleTimeout)
  }

  resetPosition() {
    const $fields = $('[data-id]', this.$svgCircles)
    $fields.each((_, el) => {
      $(el).css('transition', 'none').css('offset-distance', '0%')
    })
  }

  resetCirclePoints() {
    const $fields = $('[data-id]', this.$svgCircles)
    $fields.each((_, el) => {
      const distance = $(el).css('offset-distance').replace('%', '')
      const currentDistance = distance > 105 ? distance - 100 : distance

      $(el)
        .css('transition', 'none')
        .css('offset-distance', `${currentDistance}%`)
    })
    $fields
      .sort((prev, next) => {
        const an = +$(prev).css('offset-distance').replace('%', '')
        const bn = +$(next).css('offset-distance').replace('%', '')

        if (an > bn) {
          return 1
        }
        if (an < bn) {
          return -1
        }
        return 0
      })
      .appendTo($fields.parent())
  }

  moveItems(currentIndex) {
    const copied = [...this.$rootEl.find('.js-svgMoveItem')]

    const item = this.$rootEl.find('.js-svgMoveItem')
    const parent = item.parent()

    const shift = {
      0: 2,
      1: 1,
      2: 0,
      3: 4,
      4: 3,
    }

    parent.html(
      copied.concat(copied.splice(0, copied.length - shift[currentIndex])),
    )
  }

  checkNavigationButtons() {
    if (this.$rootEl.find('.js-svgMoveItem').eq(1).hasClass('is_hidden')) {
      this.$nextButton.addClass('is_disabled')
    } else {
      this.$nextButton.removeClass('is_disabled')
    }

    if (this.$rootEl.find('.js-svgMoveItem').eq(3).hasClass('is_hidden')) {
      this.$prevButton.addClass('is_disabled')
    } else {
      this.$prevButton.removeClass('is_disabled')
    }
  }
}
