const configSvg = {
  desktop: {
    fullPath: {
      d: 'M598.305 344.071C598.308 349.596 593.83 354.076 588.305 354.076L40.9001 354.076C38.2788 354.076 35.7622 353.047 33.8923 351.21L2.99217 320.855C1.07816 318.975 4.52149e-07 316.405 4.89327e-07 313.722L4.69787e-06 10C4.7744e-06 4.47715 4.47716 5.33895e-08 10 1.19249e-07L603.648 7.19843e-06C609.171 7.26429e-06 613.648 4.47716 613.648 10L613.648 131.712C613.648 134.364 612.594 136.907 610.719 138.783L601.14 148.361C599.264 150.238 598.21 152.784 598.211 155.437L598.305 344.071Z',
    },
    smallPath: {
      d: 'M135.586 343.571C135.589 349.096 131.111 353.576 125.586 353.576L40.9001 353.576C38.2788 353.576 35.7623 352.547 33.8923 350.71L2.99219 320.355C1.07818 318.475 1.59439e-05 315.905 1.58121e-05 313.222L9.22261e-07 10C6.51059e-07 4.47714 4.47715 5.33895e-08 10 1.19249e-07L140.93 1.68057e-06C146.452 1.74643e-06 150.93 4.47714 150.93 10L150.929 131.212C150.929 133.864 149.876 136.407 148.001 138.283L138.422 147.861C136.545 149.738 135.492 152.284 135.493 154.937L135.586 343.571Z',
    },
  },
  mob: {
    fullPath: {
      d: 'M6.15101 212.177C2.79438 212.178 0.0728175 209.457 0.0728175 206.101L0.072815 21.9761C0.072815 20.3832 0.698257 18.854 1.8145 17.7177L17.2252 2.03012C18.3677 0.867064 19.9297 0.211914 21.5601 0.211914H291.496C294.852 0.211914 297.573 2.93247 297.573 6.28844V214.135C297.573 217.491 294.852 220.212 291.496 220.212H201.966C200.354 220.212 198.809 219.572 197.669 218.432L193.144 213.907C192.004 212.767 190.457 212.126 188.845 212.127L6.15101 212.177Z',
    },
    smallPath: {
      d: 'M4.53134 49.9737C2.17879 49.9749 0.271054 48.0681 0.271055 45.7155L0.271056 15.4633C0.271056 14.3471 0.70934 13.2755 1.49156 12.4793L12.2908 1.48603C13.0914 0.671016 14.1859 0.211915 15.3284 0.211915L144.865 0.211922C147.216 0.211922 149.123 2.11837 149.123 4.4701L149.123 51.347C149.123 53.6987 147.216 55.6052 144.865 55.6052L82.1255 55.6052C80.9962 55.6052 79.9131 55.1565 79.1145 54.358L75.9436 51.187C75.1445 50.3879 74.0606 49.9393 72.9305 49.9398L4.53134 49.9737Z',
    },
  },
}

function setActiveSvg($el) {
  const currentSvg = $el.find('.home-news-card__svg-path')
  const currentSvgBorder = $el.find('.home-news-card__svg-path-border')

  const screen = $(window).width() >= 768 ? 'desktop' : 'mob'

  currentSvg.attr({
    d: configSvg[screen].fullPath.d,
  })
  currentSvgBorder.attr({
    d: configSvg[screen].fullPath.d,
  })

  $el.siblings().find('.home-news-card__svg-path').attr({
    d: configSvg[screen].smallPath.d,
  })
  $el.siblings().find('.home-news-card__svg-path-border').attr({
    d: configSvg[screen].smallPath.d,
  })

  $el
    .addClass('home-news-card_is_active')
    .siblings()
    .removeClass('home-news-card_is_active')
}

$('.js-homeNewsCard').on('click', (event) => {
  setActiveSvg($(event.currentTarget))

  if ($(window).width() <= 767) {
    $(event.currentTarget).parent().prepend(
      $(event.currentTarget)
    )
  }

  $('.js-homeSvgBg').css('opacity', 0)

  setTimeout(() => {
    $('.js-homeSvgBg').attr('xlink:href', $(event.currentTarget).data('bg'))
  }, 500)

  setTimeout(() => {
    $('.js-homeSvgBg').css('opacity', 1)
  }, 600)
})

function preloadBg() {
  $('.js-homeNewsCard').each((_, el) => {
    const bgSrc = $(el).data('bg')

    preloadImage(bgSrc)
  })
}

function preloadImage(url) {
  const img = new Image()
  img.src = url
}

setActiveSvg($('.home-news-card').eq(0))
preloadBg()
