$(document).on('click', 'a', (e) => {
  const target = $(e.currentTarget)

  if (target && target.attr('href')?.includes('#')) {
    const href = target.attr('href').split('#')
    const hash = href[1]

    if (hash) {
      const container = $(`#${hash}`)

      if (container.hasClass('accordion')) {
        window.accordionOpen(container.find('.js-accordionToggle'))

        setTimeout(() => {
          $('body, html').animate({
            scrollTop: container.offset().top - 100,
          })
        }, 300)
      }
    }
  }
})
