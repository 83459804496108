/* eslint-disable operator-linebreak */

export class RareSvgMoveAnimation {
  constructor(options) {
    this.configPoints = options.configPoints
    this.animateTimeout = 2500
    this.resetCircleTimeout = 2500
    this.disabled = false
    this.resetDistance = options.resetDistance
    this.$rootEl = options.rootEl
    this.$svgMoveItem = this.$rootEl.find('.js-rareSvgMoveItem')
    this.$svgCircles = $('.js-svgMoveCategoriesPoints')

    this.init()
  }

  init() {
    this.initHandlers()

    if (this.$rootEl.find('.js-rareSvgMoveItem.is_active')) {
      this.$rootEl.find('.js-rareSvgMoveItem.is_active').trigger('click')
    }
  }

  initHandlers() {
    this.$svgMoveItem.on('click', (e) => this.pointClick(e))
  }

  pointClick(e) {
    const currentIndex = $(e.currentTarget).index()
    const currentShift = this.configPoints[currentIndex]

    $('circle', this.$svgCircles).each((index, el) => {
      if (!currentShift[index]) {
        return
      }

      const transition =
        currentIndex < 2
          ? `${2000 - index * 150}ms`
          : `${2000 + index * 150}ms`

      $(el).css({
        transition,
        'offset-distance': `${currentShift[index]?.distance}%`,
        r: currentShift[index]?.r,
      })
    })

    $('circle', this.$svgCircles)
      .eq(currentIndex)
      .addClass('is_active')
      .siblings()
      .removeClass('is_active')

    this.$svgMoveItem.addClass('is_disabled')

    setTimeout(() => {
      this.moveItems(currentIndex)
    }, this.animateTimeout - 1000)

    setTimeout(() => {
      this.resetCirclePoints()
      this.$svgMoveItem.on('click', (ev) => this.pointClick(ev))
      this.$svgMoveItem.removeClass('is_disabled')
    }, this.resetCircleTimeout)
  }

  resetCirclePoints() {
    const $fields = $('circle', this.$svgCircles)
    $fields.each((_, el) => {
      const distance = $(el).css('offset-distance').replace('%', '')
      const currentDistance = distance > 105 ? distance - 100 : distance

      $(el)
        .css('transition', 'none')
        .css('offset-distance', `${currentDistance}%`)
    })
    $fields
      .sort((prev, next) => {
        const an = +$(prev).css('offset-distance').replace('%', '')
        const bn = +$(next).css('offset-distance').replace('%', '')

        if (an > bn) {
          return 1
        }
        if (an < bn) {
          return -1
        }
        return 0
      })
      .appendTo($fields.parent())
  }

  moveItems(currentIndex) {
    const copied = [...this.$rootEl.find('.js-rareSvgMoveItem')]

    const item = this.$rootEl.find('.js-rareSvgMoveItem')
    const parent = item.parent()

    const shift = {
      0: 2,
      1: 1,
      2: 0,
      3: 4,
      4: 3,
    }

    parent.html(
      copied.concat(copied.splice(0, copied.length - shift[currentIndex])),
    )
  }
}
