$(document).on('click', '.js-passwordToggle', (e) => {
  const parent = $(e.currentTarget).parents('.js-password')
  const input = parent.find('.js-passwordInput')

  parent.toggleClass('password-ui_opened')
  input.attr('type', input.attr('type') === 'password' ? 'text' : 'password')
})

$(document).ready(() => {
  setInterval(() => {
    passwordAnimate()
  }, 7000)

  setTimeout(() => {
    passwordAnimate()
  }, 2000)
})

function passwordAnimate() {
  $('.js-password').addClass('password-ui_animate')

  setTimeout(() => {
    $('.js-password').removeClass('password-ui_animate')
  }, 2000)
}
