import $ from 'jquery'
import Mustache from 'mustache'
import Inputmask from 'inputmask'
import { initMap } from './map/map'
import DeliveryCDEK from './delivery-cdek'
import CityAside from "./city-aside";
import Autocomplete from "./autocomplete";
import {asidePanelShow} from "./aside-panel";
import {showLoader, hideLoader} from "./loader";
import Num from "./num";
import {checkOrderFormState} from "./order-cart"

export default class Checkout {
  constructor($el, basketManager, app) {
    this.DOM = {}
    this.DOM.$el = $el
    this.app = app
    this.isAjaxRunning = false
    this.basketManager = basketManager

    this.init()
  }

  init() {
    this.cacheSelectors()
    this.render()
    this.bindEvents()
  }

  cacheSelectors() {
    this.DOM.$orderForm = this.DOM.$el.find('.js-checkout-form')
    this.DOM.$basketItems = this.DOM.$el.find('.js-checkout-basket-items')
    this.DOM.$basketTotal = this.DOM.$el.find('.js-checkout-basket-total')
    this.DOM.$delivery = this.DOM.$el.find('.js-order-delivery')
    this.DOM.$contact = this.DOM.$el.find('.js-order-contact')
    this.DOM.$confirm = this.DOM.$el.find('.js-order-confirm')
    this.DOM.$paysystems = this.DOM.$el.find('.js-order-paysystems')
    this.DOM.$deliveryCdek = $('.js-checkout-delivery-cdek')
    this.DOM.$cityAside = $('.js-aside-panel-city')
    this.DOM.$isCity = this.DOM.$el.find('.js-isCity')

    this.basketItemsTemplate = this.DOM.$el
      .find('.js-checkout-basket-items-template')
      .html()
    this.basketTotalTemplate = this.DOM.$el
      .find('.js-checkout-basket-total-template')
      .html()
    this.deliveryTemplate = this.DOM.$el
      .find('.js-order-delivery-template')
      .html()
    this.contactTemplate = this.DOM.$el
      .find('.js-order-contact-template')
      .html()
    this.confirmTemplate = this.DOM.$el
      .find('.js-order-confirm-template')
      .html()
    this.paysystemsTemplate = this.DOM.$el
        .find('.js-order-paysystems-template')
        .html()

    this.data = $.parseJSON(this.DOM.$el.find('.js-data').html())
    this.errors = $.parseJSON(this.DOM.$el.find('.js-errors').html())

    this.basketManager.setData(this.data)
  }

  render(refresh = false) {
    this.renderBasket()
    this.renderSummary()

    if (this.data.IS_AUTHORIZE)
    {
      this.renderDelivery()
      this.renderContact()
      this.renderPaySystem()
      this.renderConfirm()
    }

    this.createComponents()

    this.DOM.$el.find('.js-total-count').val(this.data.SUMMARY.COUNT)

    if (refresh)
    {
      this.DOM.$basketItems.find('.num').each((idx, el) => new Num($(el)))
    }

    this.DOM.$submitButton = this.DOM.$el.find('.js-order-submit-button')
  }

  createComponents() {
    const im = new Inputmask({
      mask: '+7 999 999-99-99',
      placeholder: '_',
      showMaskOnHover: true,
    })

    im.mask($('.js-phone'))

    this.DOM.$cityAsideComponent = new CityAside(this.DOM.$cityAside, this)

    this.DOM.$addressInputs = this.DOM.$el.find('.js-autocomplete')
    this.DOM.$addressInputs.map(this.initAutocomplete.bind(this))

    this.DOM.$addressInputs.each((idx, el) => {
      const $input = $(el)
      const $idInput = this.getIdInput($input)

      if ($input.val().length) {
        $idInput.data('value', $input.val())
      }
    })

    checkOrderFormState()
  }

  renderBasket() {
    this.DOM.$basketItems.html(
      Mustache.render(this.basketItemsTemplate, this.data.BASKET),
    )
  }

  renderSummary() {
    this.DOM.$basketTotal.html(
      Mustache.render(this.basketTotalTemplate, this.data.SUMMARY),
    )
  }

  renderDelivery() {
    this.DOM.$delivery.html(Mustache.render(this.deliveryTemplate, this.data))

    if (this.data.SHOW_DELIVERY) {
      this.DOM.$delivery.show()
      this.DOM.$el.find('.js-selectCity').hide()
      this.DOM.$el.find('.js-final-step-delivery').show()
      if (this.data.PROPS.CITY.VALUE) {
        this.DOM.$isCity.find('a').html(this.data.PROPS.CITY.VALUE)
        this.DOM.$isCity.show()
      }
    } else {
      this.DOM.$delivery.hide()
      this.DOM.$el.find('.js-selectCity').show()
      this.DOM.$el.find('.js-final-step-delivery').hide()
      this.DOM.$isCity.hide()
    }

    this.DOM.$cityInput = this.DOM.$delivery.find('.js-city-input')
    this.DOM.$cityFiasIdInput = this.DOM.$delivery.find('.js-city-fias-input')

    if (this.data.DELIVERY.ADDRESS)
    {
      this.DOM.$el.find('.js-address').text(this.data.DELIVERY.ADDRESS)
    }
  }

  renderPaySystem()
  {
    this.DOM.$paysystems.html(
        Mustache.render(this.paysystemsTemplate, this.data),
    )
    if (this.data.PAY_SYSTEM_CHECKED)
    {
      this.DOM.$el.find('.js-paysystem-checked').text(this.data.PAY_SYSTEM_CHECKED)
    }
  }

  renderContact() {
    this.DOM.$contact.html(
      Mustache.render(this.contactTemplate, this.data.CONTACT),
    )
    if (this.data.SHOW_DELIVERY){
      this.DOM.$contact.show()
    } else {
      this.DOM.$contact.hide()
    }
  }

  renderConfirm() {
    this.DOM.$confirm.html(Mustache.render(this.confirmTemplate, this.data))
  }

  bindEvents() {
    this.DOM.$submitButton.on('click', (e) => {
      e.preventDefault()
      if (this.DOM.$orderForm.valid()) {
        this.sendRequest(this.DOM.$orderForm, [{ name: 'save', value: true }])
      }
    })

    this.DOM.$delivery.on('change', '.js-delivery-input', e => {
      let asidePanel = $(e.currentTarget).data('aside-panel')
      this.sendRequest(this.DOM.$orderForm, [], (e) => {
        if (typeof asidePanel !== 'undefined') {
          asidePanelShow(asidePanel)
          this.cdekHandler(e)
        }
      })
    })

    // TODO вешаем change на инпут сдэка
    this.DOM.$delivery.on('click', '.js-checkout-delivery-cdek-open', (e) => {
      let asidePanel = $(e.currentTarget).data('aside-panel')
      asidePanelShow(asidePanel)
      this.cdekHandler(e)
    })

    this.DOM.$basketItems.on('change', '.js-order-basket-quantity', e => {
      this.sendRequest(
          this.DOM.$orderForm,
          [{ name: 'id', value: $(e.currentTarget).data('id') }, {name: 'quantity', value: $(e.currentTarget).val()}],
          (e) => {}, (e) => {},
          $(e.currentTarget).data('action'))
    })

    this.DOM.$basketItems.on('click', '.js-basket-delete', e => {
      this.sendRequest(
          this.DOM.$orderForm,
          [{ name: 'id', value: $(e.currentTarget).data('id') }],
          e => {},
          e => {},
          $(e.currentTarget).data('action')
      )
    })
  }

  sendRequest(
    form,
    additionalData = [],
    onSuccess = function () {},
    onError = function (errors) {},
    url = '',
  ) {
    if (this.isAjaxRunning) {
      return
    }

    this.ajaxStarted()

    let orderData = this.DOM.$orderForm.serializeArray()

    if (form && !this.DOM.$orderForm.is(form)) {
      const data = form.serializeArray()
      orderData = orderData.concat(data)
    }

    if (additionalData.length > 0) {
      orderData = orderData.concat(additionalData)
    }

    if (url.length > 0) {
      orderData = additionalData
    }

    orderData.push({
      name: 'sessid',
      value: window.app.sessid,
    })

    this.sendAjax(form, orderData, onSuccess, onError, url)

    /*if (typeof window.recaptchaGetToken === 'function')
    {
      let _this = this;
      window.recaptchaGetToken().then(function(token) {
        orderData.push({
          name: window.recaptchaInput,
          value: token
        });
        _this.sendAjax(form, orderData, onSuccess, onError, url)
      });
    } else {
      this.sendAjax(form, orderData, onSuccess, onError, url)
    }*/
  }

  sendAjax(
    form,
    orderData,
    onSuccess = function (data) {},
    onError = function (errors) {},
    url = '',
  ) {
    let urlSend = $(form).attr('action')

    if (url) {
      urlSend = url
    }

    $.ajax({
      url: urlSend,
      method: $(form).attr('method'),
      data: orderData,
    }).then((response) => {
      if (response.data) {
        let errors = response.errors
        if (errors.length > 0) {
          this.data.SUMMARY.HAS_WARNINGS = response.data.SUMMARY.HAS_WARNINGS
          this.data.SUMMARY.WARNINGS = response.data.SUMMARY.WARNINGS
        } else {
          this.data = response.data
        }

        this.redirects()
        this.basketManager.refresh(this.data)
      }

      if (response.status === 'success') {
        onSuccess(this.data)
      } else if (response.errors) {
        this.showErrors(response.errors)
        onError(response.errors)
      }

      this.ajaxFinished()
    })
  }

  ajaxStarted() {
    this.isAjaxRunning = true
    showLoader()
  }

  ajaxFinished() {
    this.isAjaxRunning = false
    hideLoader()
  }

  showErrors (errors) {
    if (errors && errors.length > 0) {
      $.map(errors, val => {
        if (val.code === 'invalid_csrf') {
          window.location.reload()
        }
      })
    }
  }

  redirects() {
    if (this.data.CREATED_URL) {
      window.location.href = this.data.CREATED_URL
    }

    if (this.data.SUMMARY && this.data.SUMMARY.COUNT <= 0) {
      window.location.reload()
    }
  }

  refresh(onSuccess = function () {}) {
    this.sendRequest(this.DOM.$orderForm, [], onSuccess)
  }

  cdekHandler(e) {
    if (this.data.DELIVERY.PICKUP && this.data.DELIVERY.PICKUP.STORES) {
      const cdekInst = new DeliveryCDEK(
          this.DOM.$deliveryCdek,
          this.data.DELIVERY.PICKUP.STORES,
          this
      )
      initMap({
        data: this.data.DELIVERY.PICKUP.STORES,
        placemarkOnClick: (item) => {
          cdekInst.renderDetail(item.PICKUP_POINT_ID)
        },
      })
    }
  }

  initAutocomplete (idx, input) {
    const $input = $(input)
    const $wrapper = $input.siblings('.suggestions-wrapper')
    const src = $input.data('ajax-url')
    const parentId = this.getParentId(idx)
    const $idInput = this.getIdInput($input)

    try
    {
      $input.typeahead('destroy');
      $wrapper.html('');
    }
    catch (exception)
    {

    }

    const ac = new Autocomplete($input,
        {
          menu: $wrapper,
          hint: false,
          highlight: true,
          minLength: 1,
          autoselect: true,
        },
        {
          display: "value",
          templates: {
            suggestion: data => {
              if (data.unrestricted_value) {
                return `<div class="tt-suggestion tt-selectable"><div>${data.unrestricted_value}</div></div>`;
              } else {
                return `<div class="tt-suggestion tt-selectable"><div>${data.value}</div></div>`;
              }
            },
            empty: $input.attr('data-typeahead-empty') ? '<div class="tt-suggestion tt-selectable">'
                + $input.attr('data-typeahead-empty') + '</div>' : false,
          },
        }
    )

    ac.data.remote.url = src.replace('%PARENT', parentId)

    ac.data.clear()
    ac.data.initialize(true)

    $input.on('typeahead:select', (e, suggestion) => {
      $idInput.val(suggestion.id)
      $idInput.data('value', suggestion.value);

      let fiasOrder = this.DOM.$delivery.find('[name="' + $idInput.attr('name') + '"]')
      fiasOrder.val($idInput.val())

      this.resetInputsAfterIndex(idx, true)

      if ((idx === this.DOM.$addressInputs.length - 1 || idx === 0) && fiasOrder.val())
      {
        this.sendRequest(this.DOM.$orderForm)
      }
    })

    $input.on('typeahead:close', e => {
      if ($input.val() !== $idInput.data('value')) {
        this.resetInput(idx, $input)

        let fiasOrder = this.DOM.$delivery.find('[name="' + $idInput.attr('name') + '"]')
        fiasOrder.val('')

        this.resetInputsAfterIndex(idx)
      }
    })

    $input.on('focus', () => {
      if ($input.val().length) {
        $input.typeahead('close')
      }
    })
  }

  resetInputsAfterIndex (idx, focusNext = false) {
    const startIdx = idx + 1

    for (let i = startIdx; i < this.DOM.$addressInputs.length; i++) {
      this.resetInput(i, this.DOM.$addressInputs.eq(i))
    }

    const $nextInput = this.DOM.$addressInputs.eq(startIdx)

    if (focusNext && $nextInput.length) {
      $nextInput.focus()
    }
  }

  resetInput (idx, $input) {
    const $idInput = this.getIdInput($input)

    $input.typeahead('val', '').trigger('input')
    $idInput.val('')
    $idInput.data('value', '')

    this.initAutocomplete(idx, $input)
  }

  getParentId (curIdx) {
    if (curIdx !== 0) {
      let prevInput = this.DOM.$addressInputs.eq(curIdx - 1)
      let fiasInput = this.getIdInput(prevInput)

      return fiasInput.val() || ''
    }
  }

  getIdInput ($input) {
    return $input.closest('.js-input').find('>input[type="hidden"]')
  }
}
