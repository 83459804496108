import { RareSvgMoveAnimation } from './RareSvgMoveAnimation'
import { StyleSvgMoveAnimation } from './StyleSvgMoveAnimation'

// Анимация категорий
if ($('.js-svgMoveCategories').length > 0) {
  new RareSvgMoveAnimation({
    rootEl: $('.js-svgMoveCategories'),
    resetDistance: [
      {
        distance: 98.1,
        r: 18.5,
      },
      {
        distance: 101.6,
        r: 35.5,
      },
    ],
    configPoints: {
      0: [
        {
          distance: 101.6,
          r: 35.5,
        },
        {
          distance: 198.1,
          r: 18.5,
        },
      ],
      1: [
        {
          distance: 198.1,
          r: 18.5,
        },
        {
          distance: 201.6,
          r: 35.5,
        },
      ],
    },
  })
}

// Анимация стилей
if ($('.js-svgMoveStyle').length > 0) {
  $('.js-svgMoveStyle').each((_, el) => {
    new StyleSvgMoveAnimation({
      startCurrentIndex: 2,
      rootEl: $(el),
      resetDistance: [78.85, 83.1, 87.3, 91.55, 95.8],
      configPoints: {
        0: [95.8, 178.85, 183.1, 187.3, 191.55],
        1: [91.55, 95.8, 178.85, 183.1, 187.3],
        2: [87.3, 91.55, 95.8, 178.85, 183.1],
        3: [83.1, 87.3, 91.55, 95.8, 178.85],
        4: [78.85, 83.1, 87.3, 91.55, 95.8],
      },
    })
  })
}
