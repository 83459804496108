import { SvgMoveAnimation } from '../SvgMoveAnimation'

// Анимация стилей на мобилке
if ($('.js-svgMoveStyleMob').length > 0) {
  $('.js-svgMoveStyleMob').each((_, el) => {
    new SvgMoveAnimation({
      startCurrentIndex: 2,
      rootEl: $(el),
      resetDistance: [
        {
          distance: 66,
          r: 2.5,
        },
        {
          distance: 75,
          r: 3.5,
        },
        {
          distance: 84,
          r: 2.5,
        },
      ],
      configPoints: {
        0: [
          {
            distance: 75,
            r: 3.5,
          },
          {
            distance: 84,
            r: 2.5,
          },
          {
            distance: 166,
            r: 2.5,
          },
        ],
        2: [
          {
            distance: 84,
            r: 2.5,
          },
          {
            distance: 166,
            r: 2.5,
          },
          {
            distance: 175,
            r: 3.5,
          },
        ],
      },
    })
  })
}
