import Swiper from 'swiper'

function initSliders() {
  if ($('.js-catalogSizeSlider').length > 0) {
    $('.js-catalogSizeSlider').each((_, el) => {
      const swiper = $(el).get(0)
      const initialSlide = $(el).attr('data-initial-slide')

      new Swiper(swiper, {
        slidesPerView: $(window).width() >= 768 ? 4 : 6,
        spaceBetween: 0,
        slideToClickedSlide: true,
        centeredSlides: true,
        initialSlide,
        on: {
          slideChangeTransitionEnd() {
            $(el).find('.swiper-slide-active').trigger('click')
          },
        },
      })
    })
  }
}

window.updateCatalogSizeSliderSlider = () => {
  initSliders()
}
