import Swiper from 'swiper'
import { Navigation, EffectFade } from 'swiper/modules'

function recommedCardOverlaySize() {
  $('.js-recommendCardOverlay').width($('.js-recommendCard').parent().width())
}

$(window).on('load resize', () => {
  recommedCardOverlaySize()
})

$('.js-recommendCard')
  .on('mouseenter', (e) => {
    const current = $(e.currentTarget)
    const offset = current.offset().left

    if ($(window).width() <= 767) {
      return
    }

    $('.js-recommendCardOverlay').css({
      opacity: 1,
      transform: `translate3d(${offset}px,0,0)`,
    })
  })
  .on('mouseleave', () => {
    if ($(window).width() <= 767) {
      return
    }

    $('.js-recommendCardOverlay').css({
      opacity: 0,
    })
  })

export function initRecommendSwiper() {
  if ($('.js-recommendSwiper').length > 0) {
    $('.js-recommendSwiper').each((_, swiperEl) => {
      new Swiper($(swiperEl)[0], {
        slidesPerView: $(window).width() <= 767 ? 1 : 5,
        effect: $(window).width() <= 767 ? 'fade' : 'slide',
        fadeEffect: {
          crossFade: true,
        },
        modules: [Navigation, EffectFade],
        navigation: {
          nextEl: '.js-recommendSwiperNext',
          prevEl: '.js-recommendSwiperPrev',
        },
        spaceBetween: 0,
        breakpoints: {
          320: {
            slidesPerView: 1,
            centeredSlides: true,
          },
          768: {
            slidesPerView: 5,
            centeredSlides: false,
          },
        },
      })
    })
  }
}

initRecommendSwiper()
recommedCardOverlaySize()
