$('.js-orderCartToggle').on('click', (e) => {
  e.preventDefault()
  $('.js-orderCartLayer').addClass('is_visible')
  $('body').addClass('body-lock')
})

$('.js-orderCartClose').on('click', () => {
  $('.js-orderCartLayer').removeClass('is_visible')
  $('body').removeClass('body-lock')
})

export const checkOrderFormState = () => {
  const buttonOrderWrap = $('.button-order-wrap')

  if ($('.accordion_step.is_disabled').length) {
    buttonOrderWrap.hide()
  } else {
    buttonOrderWrap.show()
  }
}

$(document).on('click', '.js-orderNextButton', (e) => {
  const target = $(e.currentTarget)
  const accordion = target.parents('.accordion')
  const accordionNext = accordion.parent().next()
  const accordionToggle = accordion.find('.js-accordionToggle')
  const accordionContent = accordion.find('.accordion__content')
  const fields = accordionContent.find('[required]')

  let validFormStep = true

  fields.each((_, el) => {
    $(el).valid()

    if (!$(el).valid()) {
      validFormStep = $(el).valid()
    }
  })

  if (validFormStep) {
    window.accordionOpen(accordionNext.find('.js-accordionToggle'))
    window.accordionClose(accordionToggle)
    accordionNext.find('.accordion_step').removeClass('is_disabled')
    accordionNext.find('img').hide()
  }

  checkOrderFormState()
})
