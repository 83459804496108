/* eslint-disable operator-linebreak */

export class StyleSvgMoveAnimation {
  constructor(options) {
    this.configPoints = options.configPoints
    this.animateTimeout = 2500
    this.resetCircleTimeout = 2500
    this.disabled = false
    this.resetDistance = options.resetDistance
    this.$rootEl = options.rootEl
    this.$svgMoveItem = this.$rootEl.find('.js-styleSvgMoveItem')
    this.$svgCircles = $('.js-svgHomeStylePoints')

    this.init()
  }

  init() {
    this.initHandlers()
  }

  initHandlers() {
    this.$svgMoveItem.on('click', (e) => this.pointClick(e))
  }

  pointClick(e) {
    const currentIndex = $(e.currentTarget).index()
    const currentShift = this.configPoints[currentIndex]

    $('circle', this.$svgCircles).each((index, el) => {
      if (!currentShift[index]) {
        return
      }

      const transition =
        currentIndex < 2 ? `${2000 - index * 150}ms` : `${2000 + index * 150}ms`

      $(el).css({
        transition,
        r: '3',
        fill: '#141517',
        stroke: '#7C7D7F',
        'offset-distance': `${currentShift[index]}%`,
      })
    })

    $('circle', this.$svgCircles)
      .eq(currentIndex)
      .css({ r: '8.5', fill: '#5E809F', stroke: '#5E809F' })
      .addClass('is_active')
      .siblings()
      .removeClass('is_active')

    this.$svgMoveItem.addClass('is_disabled')

    setTimeout(() => {
      this.moveItems(currentIndex)
    }, this.animateTimeout - 1000)

    setTimeout(() => {
      this.resetCirclePoints()
      this.$svgMoveItem.on('click', (ev) => this.pointClick(ev))
      this.$svgMoveItem.removeClass('is_disabled')
    }, this.resetCircleTimeout)
  }

  resetCirclePoints() {
    const $fields = $('circle', this.$svgCircles)
    $fields.each((_, el) => {
      const distance = $(el).css('offset-distance').replace('%', '')
      const currentDistance = distance > 105 ? distance - 100 : distance

      $(el)
        .css('transition', 'none')
        .css('offset-distance', `${currentDistance}%`)
    })
    $fields
      .sort((prev, next) => {
        const an = +$(prev).css('offset-distance').replace('%', '')
        const bn = +$(next).css('offset-distance').replace('%', '')

        if (an > bn) {
          return 1
        }
        if (an < bn) {
          return -1
        }
        return 0
      })
      .appendTo($fields.parent())
  }

  moveItems(currentIndex) {
    const copied = [...this.$rootEl.find('.js-styleSvgMoveItem')]

    const currentEl = copied[currentIndex]

    copied.splice(currentIndex, 1)

    const item = this.$rootEl.find('.js-styleSvgMoveItem')
    const parent = item.parent()

    parent.html('')

    copied.sort((a, b) => {
      const aIsLocked = a.classList.contains('is_locked')
      const bIsLocked = b.classList.contains('is_locked')
      return aIsLocked && !bIsLocked ? 1 : !aIsLocked && bIsLocked ? 0 : -1
    })

    parent.html(copied)

    parent.append(currentEl)
  }
}
