import Swiper from 'swiper'
import { Autoplay, Pagination, Navigation } from 'swiper/modules'

export function initProductSwiper() {
  if ($('.js-productSwiper').length > 0) {
    new Swiper('.js-productSwiper', {
      modules: [Autoplay, Pagination, Navigation],
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      spaceBetween: 10,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    })
  }
}

initProductSwiper()
