import Mustache from 'mustache'
import { SvgMoveAnimation } from './SvgMoveAnimation'
import { initProductSwiper } from './product'

export default class Catalog {
  constructor($el) {
    this.DOM = {}
    this.DOM.$el = $el

    this.$preloaderCircle = this.DOM.$el.find('.js-preloaderCircle')
    this.$catalogVideoTarget = this.DOM.$el.find('#catalog-layer-video-target')
    this.$catalogContentTarget = this.DOM.$el.find('#catalog-layer-content-target')
    this.$customizeItemButton = this.DOM.$el.find('.js-customizeItemButton')
    this.$pickupItemButton = this.DOM.$el.find('.js-pickupItemButton')
    this.data = $.parseJSON(this.DOM.$el.find('.js-data').html())

    this.init()
  }

  init() {
    // Анимация категорий каталог
    new SvgMoveAnimation({
      rootEl: this.DOM.$el.find('.js-svgMoveCatalogCategories'),
      resetDistance: [
        {
          distance: 91.8,
          r: 20.5,
        },
        {
          distance: 94.32,
          r: 30.5,
        },
        {
          distance: 97.9,
          r: 2.5,
        },
      ],
      configPoints: {
        0: [
          {
            distance: 97.9,
            r: 2.5,
          },
          {
            distance: 191.8,
            r: 20.5,
          },
          {
            distance: 194.32,
            r: 30.5,
          },
        ],
        1: [
          {
            distance: 94.32,
            r: 30.5,
          },
          {
            distance: 97.9,
            r: 2.5,
          },
          {
            distance: 191.8,
            r: 20.5,
          },
        ],
      },
    })

    this.bindEvents()
  }

  bindEvents() {
    const _this = this

    $(document).on('click', '.js-catalogCard', (e) => {
      e.preventDefault()

      const currentTarget = $(e.currentTarget)
      const modelId = currentTarget.data('model-id')
      const modelData = this.data[modelId]

      currentTarget.addClass('is_active').siblings().removeClass('is_active')

      _this.$preloaderCircle.addClass('is_visible')

      _this.$pickupItemButton.fadeOut(400)
      _this.$catalogVideoTarget.fadeOut(400)
      _this.$catalogContentTarget.fadeOut(400)

      $('[data-layer-preview-image]').fadeOut(400)
      $('[data-layer-preview-content]').fadeOut(400)

      if (modelData) {
        setTimeout(() => {
          _this.renderVideo(modelData)
          _this.renderContent(modelData)
        }, 500)

        setTimeout(() => {
          _this.$preloaderCircle.removeClass('is_visible')
        }, 1000)

        setTimeout(() => {
          _this.$catalogVideoTarget.fadeIn(400)
          _this.$catalogContentTarget.fadeIn(400)
          _this.changeVisibleButtons(modelData)

          initProductSwiper()
        }, 1500)
      }
    })
  }

  renderVideo(data) {
    const template = $('#catalog-layer-video-template')[0].innerHTML
    const rendered = Mustache.render(template, data)

    this.$catalogVideoTarget.html(rendered)
  }

  renderContent(data) {
    const template = $('#catalog-layer-content-template')[0].innerHTML
    const rendered = Mustache.render(template, data)

    this.$catalogContentTarget.html(rendered)
  }

  changeVisibleButtons(data) {
    this.$customizeItemButton.fadeIn(400)
    $('.js-catalogAvailableStatus').text(data.STATUS)
    $('a', this.$customizeItemButton).attr('href', data.DETAIL_PAGE_URL)
    if (!data.AVAILABLE)
    {
      $('.js-catalogAvailableStatus').addClass('c-red')
      $('a', this.$customizeItemButton).attr('href', 'javascript:void(0)')
      $('a', this.$customizeItemButton).html('')
    }
    else
    {
      $('.js-catalogAvailableStatus').removeClass('c-red')
      $('a', this.$customizeItemButton).html('выбрать версию')
    }
  }
}

